import React, { useState, useEffect } from 'react';
import './App.css';
import BoostMeCard from './components/BoostMeCard';
import Banner from './components/Banner';
import argFlag from './assets/argFlag.png';
import euroFlag from './assets/euroFlag.png';
import satFlag from './assets/satFlag.png';
import usdFlag from './assets/usdFlag.png';
import btcFlag from './assets/btcFlag.png';
import ukFlag from './assets/ukFlag.png';
import brFlag from './assets/brFlag.png';
import canFlag from './assets/canFlag.png';
import colFlag from './assets/colFlag.png';
import mexFlag from './assets/mexFlag.png';

function App() {
  const [amountFiat, setAmountFiat] = useState('');
  const [amountBtc, setAmountBtc] = useState('');
  const [amountSats, setAmountSats] = useState('');
  const [currency, setCurrency] = useState('USD');
  const [rate, setRate] = useState(0);
  const [usdToArs, setUsdToArs] = useState(0);
  const [onChainFee, setOnChainFee] = useState(null);
  const [activeField, setActiveField] = useState(null);

  useEffect(() => {
    fetchRate();
    fetchMempoolFee();
  }, [currency]);

  useEffect(() => {
    if (activeField) {
      if (activeField === 'fiat') {
        handleFiatChange({ target: { value: amountFiat } });
      } else if (activeField === 'btc') {
        handleBtcChange({ target: { value: amountBtc } });
      } else if (activeField === 'sats') {
        handleSatsChange({ target: { value: amountSats } });
      }
    }
  }, [currency, rate]);

  const fetchRate = async () => {
    try {
      let response;
      if (currency === 'ARS') {
        response = await fetch('https://api.bluelytics.com.ar/v2/latest');
        const data = await response.json();
        setUsdToArs(data.blue.value_avg);
        const btcToUsdResponse = await fetch('https://api.coindesk.com/v1/bpi/currentprice/USD.json');
        const btcToUsdData = await btcToUsdResponse.json();
        setRate(btcToUsdData.bpi.USD.rate_float);
      } else {
        response = await fetch(`https://api.coindesk.com/v1/bpi/currentprice/${currency}.json`);
        const data = await response.json();
        setRate(data.bpi[currency].rate_float);
        setUsdToArs(1);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchMempoolFee = async () => {
    try {
      const response = await fetch('https://mempool.space/api/v1/fees/recommended');
      const feeData = await response.json();
      setOnChainFee(feeData.fastestFee);
    } catch (error) {
      console.error('Error fetching mempool data:', error);
    }
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
  };

  const handleFiatChange = (e) => {
    setActiveField('fiat');
    let fiat = e.target.value.replace(/[^0-9,]/g, '');
    setAmountFiat(fiat);

    if (!isNaN(fiat.replace(',', '.'))) {
      let btc;
      if (currency === 'ARS') {
        const usdEquivalent = parseFloat(fiat.replace(',', '.')) / usdToArs;
        btc = usdEquivalent / rate;
      } else {
        btc = parseFloat(fiat.replace(',', '.')) / rate;
      }
      setAmountBtc(btc.toFixed(8).replace('.', ','));
      setAmountSats(Math.round(btc * 100000000).toLocaleString('de-DE'));
    }
  };

  const handleFiatBlur = () => {
    setAmountFiat(formatNumber(parseFloat(amountFiat.replace(',', '.')).toFixed(2)));
  };

  const handleBtcChange = (e) => {
    setActiveField('btc');
    let btc = e.target.value.replace(',', '.');
    if (/^\d*\.?\d*$/.test(btc)) {
      setAmountBtc(btc);
      if (currency === 'ARS') {
        const usdValue = btc * rate;
        setAmountFiat(formatNumber((usdValue * usdToArs).toFixed(2)));
      } else {
        setAmountFiat(formatNumber((btc * rate).toFixed(2)));
      }
      setAmountSats(Math.round(btc * 100000000).toLocaleString('de-DE'));
    }
  };

  const handleBtcBlur = () => {
    setAmountBtc(parseFloat(amountBtc).toFixed(8).replace('.', ','));
  };

  const handleSatsChange = (e) => {
    setActiveField('sats');
    let sats = e.target.value.replace(/\./g, '').replace(/,/g, '');
    setAmountSats(Number(sats).toLocaleString('de-DE'));

    const btc = sats / 100000000;
    setAmountBtc(btc.toFixed(8).replace('.', ','));

    if (currency === 'ARS') {
      const usdValue = btc * rate;
      setAmountFiat(formatNumber((usdValue * usdToArs).toFixed(2)));
    } else {
      setAmountFiat(formatNumber((btc * rate).toFixed(2)));
    }
  };

  const handleCurrencyChange = (e) => {
    const newCurrency = e.target.value;
    setCurrency(newCurrency);
    // La conversión se manejará en el useEffect que observa los cambios en currency y rate
  };

  const handleFocus = (e) => {
    if (e.target.value === '0' || e.target.value === '0,00') {
      e.target.value = '';
    }
  };

  const clearFields = () => {
    setAmountFiat('');
    setAmountBtc('');
    setAmountSats('');
    setActiveField(null);
  };

  const calculateDisplayedRate = () => {
    return currency === 'ARS' ? (rate * usdToArs).toFixed(2) : rate.toFixed(2);
  };

  return (
    <div className="container">
      <Banner />

      <button className="button">
        <div className="btc-price">
          <em>Bitcoin BTC / {calculateDisplayedRate()} {currency}</em>
        </div>
        <div className="mempool-fees">
          <p>On-chain Fee: {onChainFee ? `${onChainFee} sat/vB` : 'Cargando...'}</p>
        </div>
      </button>

      <div className="input-group">
        <input
          type="text"
          inputMode="numeric"
          value={amountSats}
          onFocus={handleFocus}
          onChange={handleSatsChange}
          placeholder="Satoshis"
        />
        <span>Sat</span>
        <img src={satFlag} alt="Sat icon" />
        <button onClick={clearFields}>x</button>
      </div>

      <div className="input-group">
        <input
          type="text"
          inputMode="decimal"
          value={amountBtc}
          onFocus={handleFocus}
          onChange={handleBtcChange}
          onBlur={handleBtcBlur}
          placeholder="Bitcoin"
        />
        <span>BTC</span>
        <img src={btcFlag} alt="BTC icon" />
        <button onClick={clearFields}>x</button>
      </div>

      <div className="input-group">
        <input
          type="text"
          inputMode="decimal"
          value={amountFiat}
          onFocus={handleFocus}
          onChange={handleFiatChange}
          onBlur={handleFiatBlur}
          placeholder="Cantidad en Fiat"
        />
        <select
          value={currency}
          onChange={handleCurrencyChange}
        >
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
          <option value="GBP">GBP</option>
          <option value="ARS">ARS</option>
          <option value="BRL">BRL</option>
          <option value="CAD">CAD</option>
          <option value="COP">COP</option>
          <option value="MXN">MXN</option>
        </select>
        <img 
          src={currency === 'USD' ? usdFlag : 
               currency === 'EUR' ? euroFlag : 
               currency === 'GBP' ? ukFlag : 
               currency === 'ARS' ? argFlag :
               currency === 'BRL' ? brFlag :
               currency === 'CAD' ? canFlag :
               currency === 'COP' ? colFlag :
               currency === 'MXN' ? mexFlag :
               null} 
          alt={`${currency} icon`} 
        />
        <button onClick={clearFields}>x</button>
      </div>

      <div className="text-credit">
        <p>Tipo de cambio proporcionado por<a href='https://www.coindesk.com/coindesk-api' target='_blank' rel="noreferrer"> Coindesk</a>.</p>
        <p>Tipo de cambio para ARS, Dólar blue promedio, proporcionado por 
          <a href='https://bluelytics.com.ar/' target='_blank' rel="noreferrer"> Bluelytics</a>.</p>
      </div>
      <BoostMeCard />
    </div>
  );
}

export default App;