import React, { useEffect } from 'react';
import './Banner.css';
import bannerImage from '../assets/bannerSatConvert.png'; // Asegúrate de importar la imagen correctamente

const Banner = () => {
  useEffect(() => {
    const handleScroll = () => {
      const banner = document.querySelector('.banner img');
      const scrollPosition = window.scrollY;
      const maxScroll = 200;
      if (scrollPosition < maxScroll) {
        const scale = 1 - (scrollPosition / maxScroll) * 0.5;
        banner.style.transform = `scale(${scale})`;
      } else {
        banner.style.transform = `scale(0.5)`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="banner">
      <img src={bannerImage} alt="Sat Convert" />
    </div>
  );
};

export default Banner;